// src/pages/PrivacyPolicy.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="content col-sm-12 col-xs12">
        <h1>Privacy Policy</h1>
        <p>At Karmaa, we value the privacy of our users. This Privacy Policy document outlines the types of personal information that is received and collected by Karmaa and how it is used.</p>
        <p></p>
        <p>Analytics</p>
        <p>Like many other websites and mobile applications, Karmaa uses analytics to record the usage of app by users. This data is utilised to make the app better.</p>
        <p></p>
        <p>Your Personal Data</p>
        <p>Karmaa takes your mobile number for login which helps us indetify you as a unique user. Apart from mobile number, all other personal data collected are optional and a user can submit that basis their usage. Few personal data points like date of birth, place of birth, gender are crucial when the user decided to generate his or her Kundli on the app.</p>
        <p></p>
        <p>Consent</p>
        <p>By using our app, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
