// src/pages/HomePage.js
import React from 'react';
import './HomePage.css';
import './Bootstrap.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <div>
      <Header />
      <div className="section orange-section">
        <div className="col-sm-6 col-xs-12">
          <img src="/img/couple.png" alt="Couples fighting" />
        </div>
        <div className="text-content col-sm-6 col-xs-12">
          <p>Ancient wisdowm with a modern outlook for all your love and relationship problems.</p>
        </div>
      </div>
      <div className="section grid-section">
        <div className="grid-item">
          <h3>Experienced Astrologers</h3>
          <p>Astrologers who will let you understanding the plans of stars for your love and relationship problems</p>
        </div>
        <div className="grid-item">
          <h3>Understanding Coaches</h3>
          <p>Wonderful coaches who would hear your issues and help you navigated problems in your love life</p>
        </div>
        <div className="grid-item">
          <h3>Free Kundli</h3>
          <p>Generate Kundli for free on our app</p>
        </div>
        <div className="grid-item">
          <h3>Private & Confidential</h3>
          <p>All your discussions on the app are totally private and confidential</p>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

/*
<div className="section download-section">
        <h2>Download our app</h2>
        <div className="download-buttons">
          <a href="https://play.google.com/store/apps/details?id=com.ds.karmaa">
            <img src="/path-to-android-image.png" alt="Download on Android" />
          </a>
          <a href="https://apps.apple.com/us/app/karmaa/id123456789">
            <img src="/path-to-ios-image.png" alt="Download on iOS" />
          </a>
        </div>
      </div>
      */

export default HomePage;
