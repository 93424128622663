// src/pages/TermsAndConditions.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsAndConditions = () => {
  return (
    <div>
      <Header />
      <div className="content">
        <h1>Terms and Conditions</h1>
        <p>Your terms and conditions text here.</p>
        <p>By accessing our app, we assume you accept these terms and conditions in full.</p>
        <p>License</p>
        <p>Unless otherwise stated, Karmaa and/or its licensors own the intellectual property rights for all material on Karmaa. All intellectual property rights are reserved.</p>
        <p>You must not:</p>
        <p>- Republish material from app.</p>
        <p>- Sell, rent or sub-license material from app.</p>
        <p>- Reproduce, duplicate or copy material from app.</p>
        <p>Variation of Terms</p>
        <p>Karmaa is permitted to revise these terms and conditions at any time as it sees fit, and by using this app you are expected to review these terms on a regular basis.</p>
        <p></p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
